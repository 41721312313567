<template>
  <div class="home">
    <section id="homehead">
      <h1 class="maintitle">POLITIQUE<br>DE CONFIDENTIALITÉ</h1>      
      <p class="maintext"></p>
      </section>
    <section class="legals" id="anchor">
      <h2 class="legals__title">POLITIQUE DE CONFIDENTIALITÉ</h2>
      <h3 class="legals__subtitle">0.	Définitions</h3>
      <p class="legals__text">Site : site de lmcreationsnumeriques.fr<br><br>
      Client : tout professionnel ou personne physique capable au sens des articles 1123 et suivants du Code civil, ou personne morale, qui visite le Site objet des présentes conditions générales.<br><br>
      Contenu : Ensemble des éléments constituants l’information présente sur le Site, notamment textes – images – vidéos.<br><br>
      Informations clients : Ci-après dénommé « Information (s) » qui correspondent à l’ensemble des données personnelles susceptibles d’être détenues par LM Créations Numériques dans le cadre d’une demande de contact, d’un abonnement à la newsletter, d’une réalisation d’une prestation de services ou à des fins statistiques.<br><br>
      Utilisateur : Internaute se connectant, utilisant le site susnommé.<br><br>
      Informations personnelles : « Les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).<br><br>
      Les termes « données à caractère personnel », « personne concernée », « sous-traitant » et « données sensibles » ont le sens défini par le Règlement Général sur la Protection des Données (RGPD : n° 2016-679)
      </p>
      <h3 class="legals__subtitle">1.	Présentation du site internet.</h3>
      <p class="legals__text">En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet lmcreationsnumeriques.fr l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :<br>
      Propriétaire : EIRL LM CREATIONS NUMERIQUES – LIEU DIT PAUSSIS 82500 ESPARSAC / lmcreationsnumeriques.fr / N° de SIRET : 89748949800013<br>
      Responsable publication : Laurie Montagner<br>
      Hébergeur : OVH - 2 rue Kellermann 59100 Roubaix France<br>
      Déléguée à la protection des données : Laurie Montagner
      </p>
      <h3 class="legals__subtitle">2.	Gestion des données personnelles</h3>
      <p class="legals__text">Le Client est informé des réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
      <h3 class="legals__subtitle">2.1	Responsables de la collecte des données personnelles</h3>
      <p class="legals__text">Pour les Données Personnelles collectées dans le cadre d’une demande de contact l’Utilisateur, d’un abonnement à la newsletter, d’une réalisation d’une prestation de services et de sa navigation sur le Site, le responsable du traitement des Données Personnelles est Laurie Montagner.<br>
      En tant que responsable du traitement des données qu’il collecte, LM Créations Numériques s’engage à respecter le cadre des dispositions légales en vigueur. Il lui appartient notamment au Client d’établir les finalités de ses traitements de données, de fournir à ses prospects et clients, à partir de la collecte de leurs consentements, une information complète sur le traitement de leurs données personnelles et de maintenir un registre des traitements conforme à la réalité.<br>
      Chaque fois que LM Créations Numériques traite des Données Personnelles, LM Créations Numériques prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour lesquelles LM Créations Numériques les traite.
      </p>
      <h3 class="legals__subtitle">2.2	Finalité des données collectées</h3>
      <p class="legals__text">LM Créations Numériques est susceptible de traiter tout ou partie des données :
        <ul>
          <li class="legals__list">pour mener des campagnes de communication : adresse email</li>
          <li class="legals__list">pour rester en contact avec le client qui l’aurait demandé : adresse email, nom, prénom</li>
          <li class="legals__list">dans le cadre de la réalisation de prestation de service : adresse email, nom, prénom, société, adresse physique, téléphone et autres données publiques relatives à l’entreprise cliente</li>
          <li class="legals__list">dans le cadre d’analyses statistiques Google Analytics</li>
        </ul>
        LM Créations Numériques ne commercialise pas vos données personnelles qui sont donc uniquement utilisées par nécessité ou à des fins statistiques et d’analyses.
        </p>
        <h3 class="legals__subtitle">2.3	Droit d’accès, de rectification et d’opposition</h3>
      <p class="legals__text">Conformément à la réglementation européenne en vigueur, les Utilisateurs de LM Créations Numériques disposent des droits suivants :
        <ul>
          <li class="legals__list">droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs droit de verrouillage ou d’effacement des données des Utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite</li>
          <li class="legals__list">droit de retirer à tout moment un consentement (article 13-2c RGPD)</li>
          <li class="legals__list">droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD)</li>
          <li class="legals__list">droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)</li>
          <li class="legals__list">droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD)  </li>
          <li class="legals__list">droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui LM Créations Numériques devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
        </ul>
      Dès que LM Créations Numériques a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, LM Créations Numériques s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.<br>
      Si l’Utilisateur souhaite savoir comment LM Créations Numériques utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur peut contacter LM Créations Numériques à l’adresse suivante : contact@lmcreationsnumeriques.fr<br>
      Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il souhaiterait que LM Créations Numériques corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport).<br>
      Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à LM Créations Numériques par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs de LM Créations Numériques peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (https://www.cnil.fr/fr/plaintes).
      </p>
      <h3 class="legals__subtitle">2.4	Non-communication des données personnelles</h3>
      <p class="legals__text">LM Créations Numériques s’interdit de traiter, héberger ou transférer les Informations collectées sur ses Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour autant, LM Créations Numériques reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’ils présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).<br>
      LM Créations Numériques s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de LM Créations Numériques, celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les mesures de corrections prises. Par ailleurs LM Créations Numériques ne collecte aucune « données sensibles ».<br>
      Les Données Personnelles de l’Utilisateur peuvent être traitées par des filiales de LM Créations Numériques et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la présente politique.<br>
      Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des Utilisateurs de LM Créations Numériques sont principalement les agents de notre service client.</p>
      <h3 class="legals__subtitle">2.5	Types de données collectées</h3>
      <p class="legals__text">Concernant les utilisateurs du Site LM Créations Numériques, nous collectons les données suivantes qui sont indispensables au fonctionnement du service, et qui seront conservées pendant une période maximale de 360 mois après la fin de la relation contractuelle : nom, prénom, email.<br>
      Ces données sont conservées pour une période maximale de 360 mois après la fin de la relation contractuelle.
      </p>
       <h3 class="legals__subtitle">3.	Notification d’incident</h3>
      <p class="legals__text">Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d'une brèche de la sécurité, nous avertirions les utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées. Nos procédures de notification d’incident tiennent compte de nos obligations légales, qu'elles se situent au niveau national ou européen. Nous nous engageons à informer pleinement nos clients de toutes les questions relevant de la sécurité de informations personnelles et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting.<br>
      Aucune information personnelle de l'utilisateur du site LM Créations Numériques n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de LM Créations Numériques  et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site LM Créations Numériques.<br>
      Pour assurer la sécurité et la confidentialité des Données Personnelles, LM Créations Numériques utilise des réseaux protégés par des dispositifs standards tels que par pare-feu et base de données sécurisée.<br>
      Lors du traitement des Données Personnelles, LM Créations Numériques comprend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.
      </p>
       <h3 class="legals__subtitle">4.	Liens hypertextes « cookies » et balises (“tags”) internet</h3>
      <p class="legals__text">Le site LM Créations Numériques contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de LM Créations Numériques. Cependant, LM Créations Numériques n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.<br>
      Sauf si vous décidez de désactiver les cookies, vous acceptez que le site puisse les utiliser. Vous pouvez à tout moment désactiver ces cookies et ce gratuitement à partir des possibilités de désactivation qui vous sont offertes et rappelées ci-après, sachant que cela peut réduire ou empêcher l’accessibilité à tout ou partie des Services proposés par le site.
      </p>
       <h3 class="legals__subtitle">4.1	« COOKIES »</h3>
      <p class="legals__text">Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.<br>
      LM Créations Numériques est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à LM Créations Numériques d’améliorer le contenu du Site, la navigation de l’Utilisateur et à des fins statistiques internes.
      Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal. LM Créations Numériques informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.<br>
      Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait également être le cas lorsque LM Créations Numériques ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet.<br>
      Le cas échéant, LM Créations Numériques décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des services éventuellement proposés par LM Créations Numériques, résultant du refus de Cookies par l’Utilisateur, et de l’impossibilité pour LM Créations Numériques d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur. Pour la gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de Cookies.<br>
      À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. LM Créations Numériques pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.<br>
      Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Facebook Instagram et Youtube figurant sur le Site de LM Créations Numériques ou dans son application mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet ou l’application mobile de LM Créations Numériques, Facebook, Instagram et Youtube peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).<br>
      Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet ou l’application mobile de LM Créations Numériques. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que LM Créations Numériques dépose ce type de cookies.
      </p>
       <h3 class="legals__subtitle">4.2	BALISES (“TAGS”) INTERNET</h3>
      <p class="legals__text">LM Créations Numériques peut employer occasionnellement des balises Internet (également appelées « tags », ou balises d’action, GIF à un pixel, GIF transparents, GIF invisibles et GIF un à un) et les déployer par l’intermédiaire d’un partenaire spécialiste d’analyses Web (Google Analytics) susceptible de se trouver (et donc de stocker les informations correspondantes, y compris l’adresse IP de l’Utilisateur) dans un pays étranger.<br>
      Ces balises sont placées à la fois dans les publicités en ligne permettant aux internautes d’accéder au Site, et sur les différentes pages de celui-ci.  
      Cette technologie permet à LM Créations Numériques d’évaluer les réponses des visiteurs face au Site et l’efficacité de ses actions (par exemple, le nombre de fois où une page est ouverte et les informations consultées), ainsi que l’utilisation de ce Site par l’Utilisateur.<br>
      Le prestataire externe pourra éventuellement recueillir des informations sur les visiteurs du Site et d’autres sites Internet grâce à ces balises, constituer des rapports sur l’activité du Site à l’attention de LM Créations Numériques, et fournir d’autres services relatifs à l’utilisation de celui-ci et d’Internet.
      </p>
      <h3 class="legals__subtitle">5.	Droit applicable et attribution de juridiction</h3>
      <p class="legals__text">Tout litige en relation avec l’utilisation du site LM Créations Numériques est soumis au droit français.</p>
    </section>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    /************** APPARITION AU SCROLL */
        var rafId = null;
        var delay = 200;
        var lTime = 0;

        function scroll() {
          var scrollTop = $(window).scrollTop();
          var height = $(window).height()
          var visibleTop = scrollTop + height;
          $('.revealleft').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
          $('.revealright').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
        }
        function reveal() {
          rafId = null;
          var now = performance.now();
          
          if (now - lTime > delay) {
            lTime = now;
            var $ts = $('.reveal_pending');
            $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
          }
          
          
          if ($('.reveal_pending').length >= 1) rafId = requestAnimationFrame(reveal);
        }

        $(scroll);
        $(window).scroll(scroll);
  }
}
</script>

<style lang="scss">

/*************** HAUT DE PAGE */
.maintitle {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 8vw;
  color: white;
  margin-bottom: 0;
  @media (max-width: 1440px) {
		font-size: 8vw;
	}
  @media (max-width: 1024px) {
		font-size: 10vw;
	}
  @media (max-width: 768px) {
		font-size: 10vw;
	}
  @media (max-width: 425px) {
		font-size: 10vw;
	}
  @media (max-width: 300px) {
		font-size: 10vw;
	}
}

#homehead {
  width: 80%;
  margin: auto;
  height: 73vh;
}

.maintext {
  font-family: 'Open Sans', sans-serif;
  color: white;
  width: 60%;
  border-top: 6px solid white;
  padding-top: 24px;
  @media (max-width: 1440px) {
		font-size: medium;
    width: 60%;
	}
  @media (max-width: 1024px) {
		font-size: medium;
    width: 80%;
	}
  @media (max-width: 768px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 425px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 300px) {
		font-size: small;
    width: 80%;
	}
}

/********************* MENTIONS */

.legals {
  width: 70%;
  margin: auto;
  @media (max-width: 768px) {
    width: 90%;
	}
  &__title {
        font-family: 'Yanone Kaffeesatz', sans-serif;
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 10px;
        position: relative;
        width: 100%;
        text-align: left;
        &::before {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        top: calc( 50% - 1.5px );
        border-bottom: 20px solid #38e01f;
        z-index: -1;
        }
  }
  &__subtitle {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &__list {
    margin-left: 5%;
  }
}

/********* Apparition au scroll */

.revealleft {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.revealright {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.reveal_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

</style>